import React from 'react';
import Scroll from 'react-scroll';
const Link = Scroll.Link;

export default class Nav extends React.Component {
  state = {show: false}

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (e) => {
    this.setState({show: window.scrollY ? true : false})
  }

  render() {
    return (
  <header className={'header-fixed header-transparent' + (this.state.show ? ' sticky-nav' : '')} style={{boxShadow: 'none', width: '100%', paddingLeft: 0, paddingRight: 0}}>
      <div className="header-area">
        <div className="container sm-100">
          <div className="row">
            <div>
              <div className="menu-area hidden-xs">
                <nav style={{textAlign: 'center', fontFamily: 'muli, sans-serif'}}>
                  <ul className="basic-menu clearfix" style={{display: 'inline-block', float: 'none'}}>
                    <li style={{cursor: 'pointer'}}><Link activeClass="active" to="overview" spy={true} smooth={true} duration={500}>Overview</Link></li>
                    <li style={{cursor: 'pointer'}}><Link activeClass="active" to="services" spy={true} smooth={true} duration={500}>Services</Link></li>
                    <li style={{cursor: 'pointer'}}><Link activeClass="active" to="biography" spy={true} smooth={true} duration={500}>Biography</Link></li>
                    <li style={{cursor: 'pointer'}}><Link activeClass="active" to="testimonials" spy={true} smooth={true} duration={500}>Testimonials</Link></li>
                    <li style={{cursor: 'pointer'}}><Link activeClass="active" to="contact" spy={true} smooth={true} duration={500}>Contact</Link></li>
                  </ul>
                </nav>
              </div>
            {/*<MobileNav />*/}
            </div>
          </div>
        </div>
      </div>
      { this.state.show ? <ScrollButton /> : null }
    </header>
    )
  }
}

const MobileNav = (props) => (
  <div className="basic-mobile-menu visible-xs">
    <nav id="mobile-nav">
      <ul>
        <li><a href="index.html">Overview</a></li>
        <li><a href="index.html">Services</a></li>
        <li><a href="index.html">Biography</a></li>
        <li><a href="index.html">Testimonials</a></li>
        <li><a href="index.html">Contact</a></li>
      </ul>
    </nav>
  </div>
)

const ScrollButton = (props) => (
  <a id="scrollUp" href="#"
    onClick={(e) => {e.preventDefault(); Scroll.animateScroll.scrollToTop()}}
    style={{position: 'fixed', zIndex: '2147483647', bottom: 0}}><i className="ion-chevron-up"></i>
  </a>
)
