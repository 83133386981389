import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Nav from '../components/nav';
import Footer from '../components/footer';
import FavIcon from '../img/favicon.ico';
import '../styles';

export default (props) => (
  <div>
    <Helmet
      title="Find The Words - Michelle Jacobs Antle"
      description="Michelle Antle is a specialist in strategic, high-impact communications. She is passionate about the power and eloquence of both the spoken and written word. She works with clients to craft and edit custom messaging that informs, influences, inspires, and impresses audiences.">
      <link rel="shortcut icon" href={FavIcon} type="image/x-icon" />
      <link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css" />
      <link href='https://fonts.googleapis.com/css?family=Unica+One%7CNoticia+Text%3A400%2C400italic%7CLato%3A400%2C300%2C700%2C300italic%2C400italic%2C700italic%26subset%3Dlatin' rel='stylesheet' type='text/css' />
    </Helmet>
    <Nav />
    <div>{props.children}</div>
    <Footer />
  </div>
)
