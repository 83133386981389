import React from 'react';

export default (props) => (
  <footer>
    <div className="basic-footer text-center gray-bg ptb-30" style={{background: 'rgb(10, 29, 57)'}}>
      <div className="container">
        <div className="footer-logo">
          <h3 style={{color: '#fff'}}>INFORM. INFLUENCE. INSPIRE. IMPRESS.</h3>
        </div>
        <div className="copyright" style={{marginTop: '10px', color: '#fff'}}>
          <p style={{color: '#fff'}}>All copyright © reserved by <span style={{fontFamily: 'Amatic SC, cursive', fontSize: '20px', fontWeight: 700}}>Find The Words</span></p>
        </div>
      </div>
    </div>
  </footer>
)
